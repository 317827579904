:root {
  --changelog-top: 50px;
  /* 
    we dont have access to these color tokens in the current version of polaris
    but they're used in the left sidebare design so we'd need to remove these
    once we finally migrate to v12 of polaris
  */
  --p-color-surface-secondary-temp: rgb(247, 247, 247);
  --p-color-surface-success-temp: rgb(205, 254, 225);
}

/* This is a bug in Polaris scroll lock mechanism */
/* I'm disabling the scroll lock till Polaris fixes this */
[data-lock-scrolling] [data-lock-scrolling-wrapper] {
  overflow: unset !important;
  height: unset !important;
}

@media screen and (prefers-reduced-motion: reduce) {
  /**
        * Use this class on elements that have transitions/ animations
        * which are solely present for aethetics	
        */
  .non-essential-animation {
    transition: none !important;
  }
}

body {
  background: #f5f5f6 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

svg text {
  font-family: Arial, Helvetica, sans-serif;
}

a {
  color: #3c73ff;
}

* {
  box-sizing: border-box;
  text-decoration: none;
  scroll-behavior: smooth;
}

p,
ul,
li {
  margin: 0;
}

button {
  background-color: transparent;
}

/**
    * Custom scrollbar
    * Only Weblkit has support for custom scrollbars i.e Chrome Browsers
    */
::-webkit-scrollbar {
  width: 12px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: #d2dce7;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #b1bcc6;
  border-radius: 10px;
  transition: 0.3s ease;
}
::-webkit-scrollbar-thumb:hover {
  background: #454f5b;
}

/* Custom styles for libraries */
/* rc-tooltip */
.rc-tooltip.rc-tooltip-zoom-enter,
.rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}
.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  animation-play-state: paused;
}
.rc-tooltip-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  animation-play-state: paused;
}
.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  animation-name: rcToolTipZoomIn;
  animation-play-state: running;
}
.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  animation-name: rcToolTipZoomOut;
  animation-play-state: running;
}
@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
}
@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
}
.rc-tooltip {
  position: absolute;
  z-index: #f5f5f6;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.5;
  opacity: 1;
}
.rc-tooltip-hidden {
  display: none;
}
.rc-tooltip-placement-top,
.rc-tooltip-placement-topLeft,
.rc-tooltip-placement-topRight {
  padding: 5px 0 0 0;
}
.rc-tooltip-placement-right,
.rc-tooltip-placement-rightTop,
.rc-tooltip-placement-rightBottom {
  padding: 0 5px 0 0;
}
.rc-tooltip-placement-bottom,
.rc-tooltip-placement-bottomLeft,
.rc-tooltip-placement-bottomRight {
  padding: 0 0 5px 0;
}
.rc-tooltip-placement-left,
.rc-tooltip-placement-leftTop,
.rc-tooltip-placement-leftBottom {
  padding: 0 0 0 5px;
}
.rc-tooltip-inner {
  text-align: left;
  text-decoration: none;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #d2dce7;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  min-height: 34px;
}
.rc-tooltip-arrow {
  display: none;
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-tooltip-arrow::after {
  content: '';
  position: absolute;
  border-style: solid;
  border-color: transparent;
}
.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  bottom: 4px;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #d2dce7;
}
.rc-tooltip-placement-top .rc-tooltip-arrow::after,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow::after,
.rc-tooltip-placement-topRight .rc-tooltip-arrow::after {
  bottom: 2px;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #ffffff;
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}
.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  left: 4px;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #d2dce7;
}
.rc-tooltip-placement-right .rc-tooltip-arrow::after,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow::after,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow::after {
  left: 2px;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #ffffff;
}
.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  right: 4px;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #d2dce7;
}
.rc-tooltip-placement-left .rc-tooltip-arrow::after,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow::after,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow::after {
  right: 2px;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #ffffff;
}
.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  top: 4px;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #d2dce7;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow::after,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow::after,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow::after {
  top: 2px;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #ffffff;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15%;
}

/* Changelog Canny App */
#canny-changelog-iframe {
  position: fixed !important;
  top: var(--changelog-top) !important;
}

.rc-tooltip-placement-top {
  /* Make sure the tooltip appears above the intercom widget */
  z-index: 2147483022;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.getting-started-tooltip {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  z-index: 2147483022;
}

.getting-started-tooltip .rc-tooltip-inner {
  border: none;
  border-radius: 12px;
}

.barn-select,
.date-range-filter {
  z-index: 2147483023;
}

.rc-tooltip {
  font-size: 14px;
}

/* adding this because tabs content disappearing on mobile https://github.com/Shopify/polaris/issues/9337 */
.Polaris-Tabs__Outer {
  height: auto;
}

/* Center aligning polaris legend */
.chart-container div[class^='_Container_'][role='list'] {
  justify-content: center !important;
}

.scrollbar-hidden {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.Polaris-Modal-Dialog__Modal .Polaris-Button--variantPrimary:not(.Polaris-Button--toneCritical) {
  box-shadow: var(--p-shadow-button-primary-success);
  background: var(--p-color-bg-fill-success);
}

.Polaris-Modal-Dialog__Modal:hover .Polaris-Button--variantPrimary:hover:not(.Polaris-Button--toneCritical) {
  box-shadow: var(--p-shadow-button-primary-success-inset);
  background: var(--p-color-bg-fill-success-hover);
}

.brevo-mfe-header-container,
.brevo-left-container {
  display: none;
}